import { useFeatureFlag } from '@getvim/feature-flags-react';
import { Insurer } from '@getvim-os/standard';
import { isEmpty } from 'lodash-es';
import { useCallback } from 'react';
import { trackSearchResults } from '../../../../../analytics';
import { AnalyticsSearchResultsPayload, SearchMethod } from '../../../../../analytics/types';

import { extractMemberTokenForSource } from '../../../../../utils/memberToken';
import { isPatientEligible } from '../../../../../utils/patientEligibility';
import { searchIdsManager, SearchSessionPayload } from '../../../../../utils/searchIdsManager';
import { getLongestDistance } from '../../../consts/searchFilters';
import { orderAssistLogger } from '../../../logger';
import { logic } from '../../../logic';
import { formatInputBySource } from '../../../source/formatter';
import {
  PreferredProvidersResult,
  SearchState,
  WidgetSource,
  DataFilters,
  GetOrderAssistInput,
  GetOrderAssistResult,
  OrderAssistFormData,
  OrderAssistOption,
  ScreenName,
  AppPatient,
} from '../../../types';

import { sumLengthOfArrays } from '../../../utils/general';
import { calculateSearchParams } from '../../../utils/search';
import { wrapWithExecutionLogger } from '../../../utils/wrapWithExecutionLogger';
import { SearchActionType, SearchDispatch } from '../reducers/searchReducer';
import { exportAnalyticsClient } from '../../../../../analytics/clients/exportAnalytics.client';
import { referralRequestAnalyticsClient } from '../../../../../analytics/referral-request';
import { UserConfig } from '../../../../../hooks';
import { useOrderAssistAppFeatureFlags } from '../OrderAssistFFWrapper';
import { extractPatientSourceVaultToken } from '@getvim-core-apps/organizations';

const calcShouldModifySearch = (
  isWiderDistanceAvailable: boolean,
  results?: GetOrderAssistResult,
  filtersData?: DataFilters,
) => {
  const longestDistance = getLongestDistance(isWiderDistanceAvailable);
  const filteredDistance = filtersData?.resultsFilters?.distance;
  const isDistanceModificationRequired = !filteredDistance || filteredDistance < longestDistance;

  const hasResults = !!results?.searchResults?.length || !!results?.alternativeResults?.length;

  return !hasResults && isDistanceModificationRequired;
};

type UseSearchParams = {
  searchDispatch: SearchDispatch;
  userConfig: UserConfig;
  source?: WidgetSource;
  isWiderDistanceAvailable: boolean;
  defaultDistanceFilter: number;
  notifyResult: (
    patient: AppPatient,
    firstResult: OrderAssistOption,
    resultSize: number,
    hasNextPage: boolean,
    inNetwork: boolean,
    vimSpecialtyName: string,
    isModifiedSearch: boolean,
    resultsFilterDistance?: number,
  ) => void;
  onSearchStart: () => void;
  patient?: AppPatient;
} & Pick<
  SearchState,
  'isCostAvailable' | 'lastProviderCacheId' | 'filtersData' | 'formDataMetadata'
> & {
    mockInsurer?: Insurer;
    referringProviderNpi?: string;
    vimReferralId?: string;
    userExternalId?: string;
  };

export type OnSearchMethod = (props: OnSearchMethodProps) => Promise<void>;

export type PreferredProvidersProps = {
  fetchPreferredProviders: boolean;
  currentCount?: number;
};

export type OnSearchMethodProps = {
  ehrNativeSpecialty?: string;
  searchFormData: OrderAssistFormData;
  filtersData?: DataFilters;
  method?: SearchMethod;
  page?: number;
  preferredProviders: PreferredProvidersProps;
  isModifiedSearch?: boolean;
};

export type UseSearchHookType = (props: UseSearchParams) => OnSearchMethod;

export const useSearch: UseSearchHookType = ({
  searchDispatch,
  patient,
  isCostAvailable,
  lastProviderCacheId,
  userConfig,
  source,
  referringProviderNpi,
  vimReferralId,
  isWiderDistanceAvailable,
  defaultDistanceFilter,
  notifyResult,
  onSearchStart,
  userExternalId,
  formDataMetadata,
}: UseSearchParams): OnSearchMethod => {
  const {
    showNotificationForZeroResults,
    addCurrentScreenToSearchPerformed,
    shouldUseSourceVaultTokens,
  } = useOrderAssistAppFeatureFlags();

  const [displayPreferredProvidersListFF] = useFeatureFlag({
    flagName: 'displayPreferredProviderList',
    defaultValue: false,
    flagContext: { insurer: patient?.insurance?.insurer, source },
  });

  const updateSessionAndSearchIds = useCallback(
    (searchPayload: GetOrderAssistInput) => {
      const payload: SearchSessionPayload = {
        vimSpecialtyId: searchPayload.searchInput?.vimSpecialtyId,
        cpts: searchPayload.searchInput?.cpt,
        patientId: patient?.patientId,
        vimReferralId,
        userExternalId,
      };
      const { searchId, searchSessionId } = searchIdsManager.handleShouldUpdate(payload);
      const newContextFields = {
        search_id: searchId,
        search_session_id: searchSessionId,
      };
      referralRequestAnalyticsClient.updateContext(newContextFields);
      exportAnalyticsClient.updateContext(newContextFields);
    },
    [patient?.patientId, vimReferralId, userExternalId],
  );

  return useCallback(
    async ({
      ehrNativeSpecialty,
      searchFormData,
      filtersData,
      method = SearchMethod.MANUAL,
      page = 1,
      preferredProviders,
      isModifiedSearch = false,
    }: OnSearchMethodProps) => {
      if (isEmpty(patient)) {
        orderAssistLogger.warning(`Missing patient to search!`);
        return;
      }

      const isEligible = await isPatientEligible(patient, userConfig, shouldUseSourceVaultTokens);

      if (!source || !isEligible) {
        orderAssistLogger.warning(`Patient isn't eligible! aborting search.`, {
          vimPatientId: patient.vimPatientId,
          userConfig,
          source,
        });

        return;
      }

      const token = shouldUseSourceVaultTokens
        ? extractPatientSourceVaultToken(patient.patientSourceVaultTokens, source)?.sourceVaultToken
        : extractMemberTokenForSource(patient.memberTokens, source);

      const updatedSearchFormData = formatInputBySource(source, {
        searchDispatch,
        searchFormData,
        options: { pagination: { lastProviderCacheId, paginationNumber: page } },
      });
      searchDispatch({
        type: SearchActionType.ON_SEARCH_START,
        payload: { isModifiedSearch },
      });

      onSearchStart();

      const { input, options } = calculateSearchParams({
        searchFormData: updatedSearchFormData,
        patient,
        token,
        isCostAvailable,
        searchFilters: filtersData?.resultsFilters,
        isWiderDistanceAvailable,
        defaultDistanceFilter,
        shouldUseSourceVaultTokens,
      });
      const searchPayload = Object.keys(options).length ? { ...input, options } : input;

      const fetchPreferred = async (): Promise<PreferredProvidersResult> => {
        return displayPreferredProvidersListFF && preferredProviders?.fetchPreferredProviders
          ? await logic.getPreferredProviders(
              { ...searchPayload, shouldUseSourceVaultTokens },
              method,
              source,
            )
          : {
              organizationHasPreferredProviders: false,
              results: [],
            };
      };

      orderAssistLogger.info('Start find api call', {
        formVimPatientId: formDataMetadata?.vimPatientId,
        formVimReferralId: formDataMetadata?.vimReferralId,
        currentVimPatientId: patient.vimPatientId,
        currentReferralId: vimReferralId,
      });
      const [results, preferredProvidersResult] = await wrapWithExecutionLogger(
        'get providers results',
        Promise.all([
          logic.find({ ...searchPayload, shouldUseSourceVaultTokens }, method, source),
          fetchPreferred(),
        ]),
      );

      const resultsError = !results;

      const { address, ...omittedSearchInput } = searchPayload.searchInput;
      const omittedSearchPayload: AnalyticsSearchResultsPayload = {
        ...searchPayload,
        searchInput: {
          ...omittedSearchInput,
          vimSpecialtyDescription: searchFormData.specialty?.vimSpecialtyDescription,
          ehrNativeSpecialty,
        },
      };

      updateSessionAndSearchIds(searchPayload);

      const { fetchPreferredProviders, currentCount } = preferredProviders;
      const limitedPreferredProvidersList = preferredProvidersResult.results.slice(0, 2);
      trackSearchResults({
        method,
        searchPayload: omittedSearchPayload,
        result: results,
        currentPreferredProvidersCount: fetchPreferredProviders
          ? preferredProvidersResult?.results.length
          : currentCount || 0,
        preferredProviders: limitedPreferredProvidersList,
        costAvailable: results?.costAvailable ?? isCostAvailable,
        resultsError,
        referringProviderNpi,
        filtersData,
        isModifiedSearch,
        addCurrentScreenToSearchPerformed,
      });

      searchDispatch({
        type: SearchActionType.ON_SEARCH_FINISHED,
        payload: {
          formStateOnSearch: { searchFormData, resultsFilters: filtersData?.resultsFilters },
          searchResults: results,
          searchError: resultsError,
          updatePreferredResult: fetchPreferredProviders,
          displayPreferredProvidersListFF,
          organizationHasPreferredProviders:
            preferredProvidersResult.organizationHasPreferredProviders,
          preferredProviders: preferredProvidersResult.results,
          shouldModifySearch:
            filtersData?.lastModifiedFromScreen !== ScreenName.OrderAssistResults &&
            !resultsError &&
            calcShouldModifySearch(isWiderDistanceAvailable, results, filtersData),
        },
      });

      const firstResult = preferredProvidersResult?.results?.[0] ?? results?.searchResults[0];
      const resultSize = sumLengthOfArrays([
        preferredProvidersResult?.results,
        results?.alternativeResults,
        results?.searchResults,
      ]);
      const hasNextPage = results?.pagination.hasNextPage;
      if ((firstResult || showNotificationForZeroResults) && method === SearchMethod.AUTOMATIC) {
        notifyResult(
          patient,
          firstResult,
          resultSize,
          !!hasNextPage,
          !results?.isOutOfNetworkResults,
          searchFormData.specialty?.vimSpecialtyDescription || '',
          isModifiedSearch,
          filtersData?.resultsFilters?.distance,
        );
      }
    },
    [
      patient,
      userConfig,
      source,
      searchDispatch,
      lastProviderCacheId,
      onSearchStart,
      isCostAvailable,
      isWiderDistanceAvailable,
      defaultDistanceFilter,
      formDataMetadata?.vimPatientId,
      formDataMetadata?.vimReferralId,
      vimReferralId,
      updateSessionAndSearchIds,
      referringProviderNpi,
      addCurrentScreenToSearchPerformed,
      displayPreferredProvidersListFF,
      showNotificationForZeroResults,
      notifyResult,
    ],
  );
};
