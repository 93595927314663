import { v4 as uuidv4 } from 'uuid';
import {
  AnalyticsContext,
  ReferralRequestAnalyticsEvents,
  ReferralRequestEventNames,
} from '../types/referralRequestAnalyticsEvents.types';
import {
  ReferralStep,
  ReferralSteps,
} from '../../components/app/components/referral-request/types';
import { WriteBackProvider, WidgetSource } from '../../components/app/types';
import { writebackProviderToAnalyticsProvider } from '../referral-request/formatters';
import { vimPatientIdsManager } from '../../utils/vimPatientIdsManager';
import { OrderAssistAnalyticsClient } from './types';
import { isVimOsAppMode } from '../../vim-os-sdk-migration';
import { orderAssistLogger } from '../../components/app/logger';
import { AppsAnalyticsBaseProperties } from '../types/orderAssistAppAnalytics.types';

export class ReferralRequestAnalyticsManager {
  private context?: AnalyticsContext;
  private static analyticsClient: OrderAssistAnalyticsClient;
  private baseProperties = {};

  public track(
    event: ReferralRequestEventNames,
    eventProperties: ReferralRequestAnalyticsEvents[ReferralRequestEventNames],
  ) {
    ReferralRequestAnalyticsManager.analyticsClient.trackAnalytics({
      event,
      properties: {
        ...this.baseProperties,
        ...eventProperties,
        referralRequestContext: this.context,
        vim_patient_id: vimPatientIdsManager.getVimPatientId(),
        is_vim_os: isVimOsAppMode,
      },
    });
  }

  public static setAnalyticsClient(client: OrderAssistAnalyticsClient) {
    ReferralRequestAnalyticsManager.analyticsClient = client;
  }

  public setCurrentScreen(currentScreen: keyof ReferralSteps) {
    if (this.context) {
      this.context.current_screen = currentScreen;
    }
  }

  updateContext(context: Partial<AnalyticsContext>) {
    this.context = { ...this.context, ...context };
  }

  public setDatasource(datasource?: WidgetSource) {
    if (this.context) {
      this.context.datasource = datasource;
    }
  }

  public initiateSession(selectedProvider: WriteBackProvider): string {
    const referralRequestSessionId = uuidv4();
    this.context = {
      ...this.context,
      referral_request_session_id: referralRequestSessionId,
      selected_provider: writebackProviderToAnalyticsProvider(selectedProvider),
      current_screen: ReferralStep.REFERRAL_REQUIRED,
    };
    orderAssistLogger.debug('Referral Request - initiated new session', {
      referralRequestSessionId,
    });
    return referralRequestSessionId;
  }

  upsertBaseProperties = (properties: Partial<AppsAnalyticsBaseProperties>) => {
    this.baseProperties = { ...this.baseProperties, ...properties };
  };

  setPatientBaseProperties({
    vimPatientId,
    ehrInsurance,
    insurer,
  }: {
    vimPatientId: string | null;
    ehrInsurance: string | null;
    insurer: string | null;
  }) {
    orderAssistLogger.debug('[ReferralRequestAnalyticsClient]: patient base properties update', {
      vimPatientId,
      ehrInsurance,
      insurer,
    });
    const properties = {
      base_properties: {
        vim_patient: {
          vim_patient_id: vimPatientId,
          ehr_insurance: ehrInsurance,
          vim_insurer: insurer,
        },
      },
    };
    this.upsertBaseProperties(properties);
  }
}

export const referralRequestAnalyticsClient = new ReferralRequestAnalyticsManager();
