import { ApplicationTitle, Button, SelectRef } from '@getvim/atomic-ui';
import { useFlowState } from '@getvim/flow-context-provider';
import { Entities } from '@getvim/vim-connect';
import React, { useCallback, useMemo, useRef } from 'react';
import { useEhrState } from '../../../../stores/ehr-state/EhrState.store';
import { getOrderAssistUIString } from '../../consts/strings';
import { SearchRequiredState } from '../SourceConfigWrapper';
import { FiltersWrapper } from '../filters';
import { CustomAction, QuickSpecialty } from '../filters/QuickSpecialty';
import {
  AddressInputWrapper,
  AdvancedSearchContainer,
  IcdCptInputWrapper,
  PlaceOfServiceInputWrapper,
  SelectInsurancePlanInput,
  SpecialtyInputWrapper,
} from '../form-inputs';
import { usePlaceOfService } from './hooks';
import './search-form.less';
import { QuickSpecialtyTypes, SearchFormProps } from './types';
import { RESET_SPECIALTY } from '../filters/specialties.consts';
import { useOrderAssistAppFeatureFlags } from '../order-assist-app/OrderAssistFFWrapper';
import { MAX_CODE_PER_SEARCH } from '../../consts/maxIcdCptPerSearch';

export const CommonForm: React.FC<SearchFormProps> = ({
  searchFilters,
  isGoogleApiLoaded,
  icdQuery,
  cptQuery,
  specialtyQuery,
  onFreeTextQuery,
  onFiltersChanged,
  onQuickSpecialtyChange,
  useDiQuickSpecialtyFF,
  useReferralQuickSpecialtyFF,
  isClearEnabled,
  onFormDataChange,
  formData,
  validationErrors,
  clearSearch,
  search,
  inputsDirtiness,
  source,
  resetDirtiness,
}) => {
  const selectElement = useRef() as SelectRef;
  const { shouldUseMaxCptsConfig } = useOrderAssistAppFeatureFlags();

  const onQuickSpecialtyReset = useCallback(() => {
    resetDirtiness('specialty');
    const innerInputRef = selectElement.current?.select;
    innerInputRef.current?.click();
  }, [resetDirtiness]);

  const { sourceConfig } = useFlowState<SearchRequiredState>();
  const {
    supportLanguage,
    supportIcdCpt,
    supportPlaceOfService,
    supportFacilityName,
    supportWiderDistanceFilter,
    defaultDistanceFilter,
    supportInsurancePlanInput,
    maxCptsPerSearch,
  } = sourceConfig;
  const { state } = useEhrState();
  const { showPlaceOfService, placeOfServiceOptions } = usePlaceOfService({
    vimSpecialtyId: formData.specialty?.vimSpecialtyId,
  });

  const quickSpecialtyConfig: { type: QuickSpecialtyTypes; actions?: CustomAction[] } | null =
    useMemo(() => {
      if (state?.order?.type === Entities.OrderType.DI && useDiQuickSpecialtyFF) {
        return { type: QuickSpecialtyTypes.DI };
      } else if (state?.referral && useReferralQuickSpecialtyFF) {
        return {
          type: QuickSpecialtyTypes.REFERRAL,
          actions: [{ ...RESET_SPECIALTY, onSelect: onQuickSpecialtyReset }],
        };
      }
      return null;
    }, [
      state?.referral,
      state?.order,
      useReferralQuickSpecialtyFF,
      useDiQuickSpecialtyFF,
      onQuickSpecialtyReset,
    ]);

  return (
    <div className="search-form">
      <ApplicationTitle title={getOrderAssistUIString('search_form_title')} />
      <FiltersWrapper
        onApply={onFiltersChanged}
        lastUsedSearchFilters={searchFilters}
        isLanguageDisabled={!supportLanguage}
        widerDistance={supportWiderDistanceFilter}
        defaultDistanceFilter={defaultDistanceFilter}
      />
      {quickSpecialtyConfig && (
        <QuickSpecialty
          type={quickSpecialtyConfig.type}
          value={formData}
          onChange={onQuickSpecialtyChange}
          customActions={quickSpecialtyConfig.actions}
        />
      )}
      <div className="search-form__input-container">
        {supportInsurancePlanInput && (
          <SelectInsurancePlanInput
            source={source}
            onChange={onFormDataChange}
            selectedInsurer={formData.insurer}
            selectedInsurancePlan={formData.insurancePlan}
          />
        )}
        <AddressInputWrapper
          value={formData.address}
          onChange={onFormDataChange}
          isGoogleApiLoaded={isGoogleApiLoaded}
          validationError={validationErrors?.address}
          isDirty={inputsDirtiness.address}
        />
        <SpecialtyInputWrapper
          value={formData.specialty}
          onChange={onFormDataChange}
          onFreeTextQuery={onFreeTextQuery}
          specialtyFreeTextQueryOptions={specialtyQuery?.results}
          loading={!!specialtyQuery?.isLoading}
          error={!!specialtyQuery?.hasError}
          validationError={validationErrors?.specialty}
          isDirty={inputsDirtiness.specialty}
          selectRef={selectElement}
        />

        {supportPlaceOfService && showPlaceOfService && (
          <PlaceOfServiceInputWrapper
            value={formData.placeOfService}
            onChange={onFormDataChange}
            placeOfServiceOptions={placeOfServiceOptions}
            validationError={validationErrors?.placeOfService}
            isDirty={inputsDirtiness.placeOfService}
          />
        )}

        {supportIcdCpt && (
          <div style={{ position: 'relative' }}>
            <IcdCptInputWrapper
              value={{
                icd: formData.icd,
                cpt: formData.cpt,
              }}
              onChange={onFormDataChange}
              onFreeTextQuery={onFreeTextQuery}
              icdFreeTextQueryOptions={icdQuery?.results ?? []}
              cptFreeTextQueryOptions={cptQuery?.results ?? []}
              cptValidationError={validationErrors?.cpt}
              inputsDirtiness={{ cpt: inputsDirtiness.cpt }}
              icdLoading={!!icdQuery?.isLoading}
              cptLoading={!!cptQuery?.isLoading}
              maxCptsPerSearch={shouldUseMaxCptsConfig ? maxCptsPerSearch : MAX_CODE_PER_SEARCH}
            />
          </div>
        )}
        <AdvancedSearchContainer
          value={{
            npi: formData.npi,
            firstName: formData.firstName,
            lastName: formData.lastName,
            ...(supportFacilityName ? { facilityName: formData.facilityName } : {}),
          }}
          onChange={onFormDataChange}
          validationError={validationErrors?.advancedSearch}
          supportFacilityName={supportFacilityName}
          inputDirtiness={{
            firstName: inputsDirtiness.firstName,
            lastName: inputsDirtiness.lastName,
            npi: inputsDirtiness.npi,
            ...(supportFacilityName ? { facilityName: inputsDirtiness.facilityName } : {}),
          }}
        />
        <div>
          <Button
            className="search-form-clear-button"
            text="Clear search"
            buttonType="small"
            bgColor={null}
            disabled={!isClearEnabled}
            onClick={clearSearch}
          />
          <Button
            className="search-form-button"
            text="Search"
            bgColor="default"
            buttonType="small"
            onClick={search}
          />
        </div>
      </div>
    </div>
  );
};

export default CommonForm;
