import { getDefaultSearchFilters } from '../../consts/searchFilters';
import {
  SearchFilters,
  GetOrderAssistInput,
  SearchOptionsInput,
  OrderAssistFormDataWithOptions,
  AppPatient,
} from '../../types';

interface CalculateSearchParamsProps {
  searchFormData: OrderAssistFormDataWithOptions;
  patient: AppPatient;
  token: string | undefined;
  isCostAvailable: boolean;
  searchFilters?: SearchFilters;
  isWiderDistanceAvailable: boolean;
  defaultDistanceFilter: number;
  shouldUseSourceVaultTokens: boolean;
}

interface SearchParams {
  input: GetOrderAssistInput;
  options: SearchOptionsInput;
}

export const calculateSearchParams = ({
  searchFormData,
  patient,
  token,
  isCostAvailable,
  searchFilters,
  isWiderDistanceAvailable,
  defaultDistanceFilter,
  shouldUseSourceVaultTokens,
}: CalculateSearchParamsProps): SearchParams => {
  const {
    firstName,
    lastName,
    facilityName,
    npi,
    specialty,
    cpt,
    icd,
    placeOfService,
    address,
    insurer,
    insurancePlan,
  } = searchFormData;
  const defaultFilters = getDefaultSearchFilters(isWiderDistanceAvailable, defaultDistanceFilter);

  const advancedSearch = { lastName, firstName, npi, facilityName };

  let referredToPlaceOfService;
  if (placeOfService) {
    referredToPlaceOfService = { code: placeOfService, display: placeOfService };
  }

  const input: GetOrderAssistInput = {
    ...(shouldUseSourceVaultTokens
      ? { anonymizedPatient: token, memberToken: undefined }
      : { memberToken: token }),
    searchInput: {
      vimSpecialtyId: specialty?.vimSpecialtyId,
      cpt: cpt?.map((element) => element.value),
      icd: icd?.map((element) => element.value),
      advancedSearch,
      referredToPlaceOfService,
      address: {
        ...(address || {}),
        zipCode: address?.zipCode ?? patient.demographics.address?.zipCode ?? '',
      },
      insurer,
      insurancePlan,
    },
  };

  const { options: searchOptions } = searchFormData;
  const { distance, language } = searchFilters || {};
  const options: SearchOptionsInput = {
    radius: distance || defaultFilters.distance,
    languageCode: language || defaultFilters.language,
    ...(searchOptions?.pagination ? { pagination: searchOptions?.pagination } : {}),
    forceCost: isCostAvailable,
  };

  return { input, options };
};
