import { RESULTS_PER_PAGE } from '../../../../consts/resultsPerPage';
import { FindQuery, UHC, GetOrderAssistInput } from '../../../../types';

import { iso6391By6392 } from '../mapping/languages';

export const getFindQuery = (
  input: GetOrderAssistInput & { shouldUseSourceVaultTokens: boolean },
): FindQuery => {
  const { searchInput, options, memberToken, anonymizedPatient, shouldUseSourceVaultTokens } =
    input;
  const {
    vimSpecialtyId,
    advancedSearch,
    icd,
    cpt,
    referredToPlaceOfService,
    address,
    insurer,
    insurancePlan,
  } = searchInput || {};
  if (!vimSpecialtyId) {
    throw new Error('No Vim specialty id provided!');
  }

  const { firstName, lastName, facilityName, npi } = advancedSearch || {};
  const { forceCost, pagination, radius, languageCode } = options || {};
  const { providerCacheId, paginationNumber } = pagination || {};

  const implementationSpecificFilters: UHC.SpecificFilters = {
    forceCost,
    providerCacheId,
    referredToPlaceOfService,
  };

  return {
    searchParams: {
      icd,
      cpt,
      geo: {
        zip: address?.zipCode,
        addressLine1: address?.address1,
        addressLine2: address?.address2,
        city: address?.city,
        state: address?.state,
      },
      ...(shouldUseSourceVaultTokens
        ? { anonymizedPatient }
        : { encryptedIdentifyInput: memberToken }),
    },
    filters: {
      vimSpecialtyIds: [vimSpecialtyId],
      npiList: npi ? [npi] : undefined,
      provider: {
        firstName,
        lastName,
      },
      facility: {
        name: facilityName,
      },
      spokenLanguage: languageCode ? iso6391By6392[languageCode]?.iso6391Code : undefined,
      distance: radius,
      implementationSpecificFilters,
      ...(insurer && insurancePlan
        ? { memberPlan: `${insurer}-${insurancePlan}`.toUpperCase() }
        : {}),
    },
    pagination: {
      pageNumber: paginationNumber ?? 1,
      pageSize: RESULTS_PER_PAGE,
    },
  };
};
