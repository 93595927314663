import { SelectOption } from '../types';

export const getOptionLabel = (code: string, name: string): string => {
  return `${code} - ${name}`;
};
export const getSelectOptions: (codes, maxCodesPerSearch) => SelectOption[] = (
  codes,
  maxCodesPerSearch,
) => {
  return codes
    .filter(({ code }) => !!code)
    .map(({ name, code }) => ({
      label: getOptionLabel(code, name),
      value: code,
    }))
    .slice(0, maxCodesPerSearch);
};
