import { InputStyle, InputStyleWrapper, InputValidationMessage, Select } from '@getvim/atomic-ui';
import { Team, useFeatureFlag } from '@getvim/feature-flags-react';
import React, { useCallback } from 'react';
import classNames from 'classnames';
import {
  FreeTextQueryPayload,
  FreeTextResult,
  FreeTextType,
  InputWrapperProps,
  SelectOption,
} from '../../types';
import { getOrderAssistUIString } from '../../consts/strings';
import { MAX_CODE_PER_SEARCH } from '../../consts/maxIcdCptPerSearch';
import { getOptionLabel } from '../../utils/icdCptFormat';

interface IcdCptInput {
  icd?: SelectOption[];
  cpt?: SelectOption[];
}

type IcdCptInputProps = Omit<InputWrapperProps<'icd' | 'cpt'>, 'value'> & {
  value: IcdCptInput;
  onFreeTextQuery: (payload: FreeTextQueryPayload) => void;
  icdFreeTextQueryOptions?: FreeTextResult[];
  cptFreeTextQueryOptions?: FreeTextResult[];
  cptValidationError?: string;
  isDisabled?: boolean;
  inputsDirtiness: { icd?: boolean; cpt?: boolean };
  icdLoading: boolean;
  cptLoading: boolean;
  maxCptsPerSearch: number;
};

const sanitizeSelectOption = (input: string): string => {
  return input.replace(/[$()*+?[\\\]^{|}]/g, '\\$&');
};

const IcdCptInputWrapper: React.FC<IcdCptInputProps> = ({
  value,
  onChange,
  onFreeTextQuery,
  icdFreeTextQueryOptions,
  cptFreeTextQueryOptions,
  cptValidationError,
  isDisabled,
  inputsDirtiness,
  icdLoading,
  cptLoading,
  maxCptsPerSearch,
}: IcdCptInputProps) => {
  const [shouldReplaceSafeStringWithLocalRegex] = useFeatureFlag({
    flagName: 'shouldReplaceSafeStringWithLocalRegex',
    defaultValue: false,
    team: Team.OrderOptimization,
  });
  const setInputChange = (payload: { type: FreeTextType; values?: SelectOption[] }) => {
    onChange({ [payload.type]: payload.values });
  };

  const sendFreeTextQuery = useCallback(
    (type: FreeTextType, freeText: string) => {
      onFreeTextQuery({ type, freeText });
    },
    [onFreeTextQuery],
  );

  const formatOptions = useCallback(
    (options: FreeTextResult[], type: FreeTextType, maxCodes: number): SelectOption[] => {
      return options.map(({ codes, description }) => ({
        value: codes[0],
        label: getOptionLabel(codes[0], description),
        disabled:
          (value[type]?.length ?? 0) >= maxCodes &&
          !value[type]?.find((element) => element.value === codes[0]),
      }));
    },
    [value],
  );

  const cptSearch = useCallback(
    ({ state, methods }) => {
      const searchString = shouldReplaceSafeStringWithLocalRegex
        ? sanitizeSelectOption(state.search)
        : methods.safeString(state.search);
      sendFreeTextQuery(FreeTextType.Cpt, searchString);
    },
    [sendFreeTextQuery, shouldReplaceSafeStringWithLocalRegex],
  );

  const icdSearch = useCallback(
    ({ state, methods }) => {
      const searchString = shouldReplaceSafeStringWithLocalRegex
        ? sanitizeSelectOption(state.search)
        : methods.safeString(state.search);
      sendFreeTextQuery(FreeTextType.Icd, searchString);
    },
    [sendFreeTextQuery, shouldReplaceSafeStringWithLocalRegex],
  );

  return (
    <>
      <InputStyleWrapper
        className={classNames('input-wrapper icd-input-wrapper', { disabled: isDisabled })}
        leftIcon={<i className="icon-check-in" />}
        rightIcon={<></>}
        inputStyle={InputStyle.pillMedium}
      >
        <div className="input">
          <Select
            multi
            clearable
            closeOnSelect
            keepSelectedInList
            label={getOrderAssistUIString('diagnosis_code_select_label')}
            placeholder=""
            id="icd-input"
            name="icd-input"
            values={value?.icd}
            labelField="label"
            valueField="value"
            optionLabelField="value"
            showChevronIcon
            options={formatOptions(
              icdFreeTextQueryOptions ?? [],
              FreeTextType.Icd,
              MAX_CODE_PER_SEARCH,
            )}
            onChange={(values: SelectOption[]) => {
              if (values.length > MAX_CODE_PER_SEARCH) {
                return;
              }
              setInputChange({ type: FreeTextType.Icd, values });
            }}
            searchFn={icdSearch}
            noDataLabel={getOrderAssistUIString('codings_no_data_label')}
            noResultsLabel={getOrderAssistUIString('codings_no_results_label')}
            noInputLabel={getOrderAssistUIString('codings_input_label')}
            isDisabled={isDisabled}
            loading={icdLoading}
          />
        </div>
      </InputStyleWrapper>

      <InputStyleWrapper
        className={classNames('input-wrapper cpt-input-wrapper', { disabled: isDisabled })}
        leftIcon={<i className="icon-syringe" />}
        rightIcon={<></>}
        inputStyle={InputStyle.pillMedium}
        hasError={inputsDirtiness.cpt && !!cptValidationError}
      >
        <div className="input">
          <Select
            multi
            label={getOrderAssistUIString('procedure_code_select_label')}
            placeholder=""
            id="cpt-input"
            name="cpt-input"
            values={value?.cpt}
            labelField="label"
            valueField="value"
            optionLabelField="value"
            clearable
            closeOnSelect
            keepSelectedInList
            showChevronIcon
            options={formatOptions(
              cptFreeTextQueryOptions ?? [],
              FreeTextType.Cpt,
              maxCptsPerSearch,
            )}
            onChange={(values: SelectOption[]) => {
              if (values.length > maxCptsPerSearch) {
                return;
              }
              setInputChange({ type: FreeTextType.Cpt, values });
            }}
            searchFn={cptSearch}
            noDataLabel={getOrderAssistUIString('codings_no_data_label')}
            noResultsLabel={getOrderAssistUIString('codings_no_results_label')}
            noInputLabel={getOrderAssistUIString('codings_input_label')}
            isDisabled={isDisabled}
            loading={cptLoading}
          />
        </div>
        {inputsDirtiness.cpt && !!cptValidationError ? (
          <InputValidationMessage>{cptValidationError}</InputValidationMessage>
        ) : null}
      </InputStyleWrapper>
    </>
  );
};

export default IcdCptInputWrapper;
