import { useEffect, useState } from 'react';

import { useSharedServiceState } from '@getvim/internal-vim-os-sdk/react';
import { PatientSourceVaultToken } from '@getvim/patient-lookup-service/types';
import { orderAssistLogger } from '../../../logger';
import { getPatientInsurance } from '../../../../../utils/insurer.utils';
import { exportAnalyticsClient } from '../../../../../analytics/clients/exportAnalytics.client';
import { referralRequestAnalyticsClient } from '../../../../../analytics/referral-request';
import { analyticsManager } from '../../../../../analytics/analyticsManager';
import { vimPatientIdsManager } from '../../../../../utils/vimPatientIdsManager';

export function usePatientLookupSharedService(
  ehrPatient,
  setPatient,
  shouldUseSourceVaultTokens: boolean,
) {
  const serviceState = useSharedServiceState();

  const [patientLookupResponse, setPatientLookupResponse] = useState<{
    patientSourceVaultTokens: PatientSourceVaultToken[];
    patientToken: string;
  }>();

  useEffect(() => {
    const { 'patient-lookup-service': patientLookupService } = serviceState;
    if (patientLookupService) {
      const { patientLookupResponse: patientLookupServiceRespose } =
        patientLookupService.customMessage.payload;
      if (patientLookupServiceRespose.patientToken !== patientLookupResponse?.patientToken) {
        setPatientLookupResponse(patientLookupServiceRespose);
      } else {
        orderAssistLogger.info(
          'Received new patient-lookup-service event for same patientToken. skipping',
        );
      }
    }
  }, [serviceState]);

  useEffect(() => {
    if (shouldUseSourceVaultTokens) {
      if (
        ehrPatient &&
        patientLookupResponse &&
        patientLookupResponse?.patientToken === ehrPatient.token
      ) {
        orderAssistLogger.info('Initialize AppPatient with patientSourceVaultTokens', {
          ehrPatient,
          patientLookupResponse,
        });

        const patientInsurance = getPatientInsurance(
          ehrPatient.insurance,
          patientLookupResponse.patientSourceVaultTokens,
        );

        setPatient({
          ...ehrPatient,
          patientSourceVaultTokens: patientLookupResponse.patientSourceVaultTokens,
          insurance: patientInsurance,
        });

        // Handle analytics baseProperties
        analyticsManager.setPatientBaseProperties({
          vimPatientId: ehrPatient.vimPatientId || null,
          ehrInsurance: patientInsurance?.ehrInsurance || null,
          insurer: patientInsurance?.insurer || null,
        });

        exportAnalyticsClient.setPatientBaseProperties({
          vimPatientId: ehrPatient.vimPatientId || null,
          ehrInsurance: patientInsurance?.ehrInsurance || null,
          insurer: patientInsurance?.insurer || null,
        });

        referralRequestAnalyticsClient.setPatientBaseProperties({
          vimPatientId: ehrPatient.vimPatientId || null,
          ehrInsurance: patientInsurance?.ehrInsurance || null,
          insurer: patientInsurance?.insurer || null,
        });

        vimPatientIdsManager.handleUpdateIds(
          ehrPatient.vimPatientId,
          undefined,
          patientLookupResponse.patientSourceVaultTokens,
        );
      } else {
        orderAssistLogger.info(
          'Reset AppPatient- ehrPatient and patientSourceVaultTokens are not matched for same patient',
          {
            ehrPatient,
            patientLookupResponse,
          },
        );

        setPatient(undefined);
        vimPatientIdsManager.resetIds();
      }
    } else {
      orderAssistLogger.info('Initialize patient without patientSourceVaultTokens', {
        ehrPatient,
      });
      setPatient(ehrPatient);

      if (ehrPatient) {
        vimPatientIdsManager.handleUpdateIds(
          ehrPatient.vimPatientId,
          ehrPatient.contentSupplierIdentifiers,
          undefined,
        );
      } else {
        vimPatientIdsManager.resetIds();
      }
    }
  }, [patientLookupResponse, ehrPatient, shouldUseSourceVaultTokens]);
}
