import {
  AppLaunchButtonClickPayload,
  OrdersRealEstate,
  ReferralRealEstate,
} from '@getvim/internal-vim-os-sdk/types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { AppActionType, AppDispatch } from '../../reducers/appReducer';
import { Diagnosis, OrderType } from '@getvim-os/types';
import { ReactVimApi, useApi, useResources } from '@getvim/internal-vim-os-sdk/react';
import { Order, Patient } from '../../../../types';
import { useOrderAssistLegacyState } from '../../../../../../vim-os-sdk-migration/ehr-state';
import { orderAssistLogger } from '../../../../logger';
import { SelectButtonCallbacks, UseSelectProviderButtonProps } from './types';
import { useOrderAssistAppFeatureFlags } from '../../OrderAssistFFWrapper';

const useSelectProviderWidgetsData = ({
  patient,
  appDispatch,
}: {
  patient: Patient | undefined;
  appDispatch: AppDispatch;
}) => {
  const [diagnosis, setDiagnosis] = useState<Diagnosis[]>();
  const [selectedOrders, setSelectedOrders] = useState<Order[]>();
  const { shouldUseSourceVaultTokens } = useOrderAssistAppFeatureFlags();
  const { patient: patientInContext, referral, orders } = useOrderAssistLegacyState();

  useEffect(() => {
    if (referral) {
      const { diagnosis: newDiagnosis } = referral || {};
      setDiagnosis(newDiagnosis);
    } else {
      setDiagnosis(undefined);
    }
  }, [referral]);

  useEffect(() => {
    if (orders?.selectedOrders?.length) {
      if (patientInContext?.patientId && patient?.patientId === patientInContext.patientId) {
        if (!shouldUseSourceVaultTokens) {
          appDispatch({
            type: AppActionType.ON_UPDATE_MEMBER_TOKEN,
            payload: { memberTokens: patientInContext.memberTokens },
          });
        }
      } else {
        setSelectedOrders(undefined);
      }

      setSelectedOrders(orders.selectedOrders || []);
    }
  }, [orders, patientInContext, patient, appDispatch]);

  return { diagnosis, selectedOrders };
};

export const useSdkSelectProviderButton = (
  {
    patient,
    appDispatch,
    supportOrdersButton,
  }: Pick<UseSelectProviderButtonProps, 'patient' | 'appDispatch' | 'supportOrdersButton'>,
  {
    onDiOrdersSelectButtonClicked,
    onLabOrdersSelectButtonClicked,
    onReferralSelectButtonClicked,
  }: SelectButtonCallbacks,
): void => {
  const {
    ehrState: { referral, orders },
  } = useResources();
  const { launchButton } = useApi();
  const { selectedOrders } = useSelectProviderWidgetsData({
    appDispatch,
    patient,
  });

  const launchButtonsConfig: Parameters<ReactVimApi['launchButton']['enableLaunchButtons']>[0] =
    useMemo(() => {
      return {
        referral: {
          REFERRAL_SELECT_PROVIDER: !!referral,
        },
        orders: {
          DI_SELECT_PROVIDER:
            supportOrdersButton &&
            !!orders?.some(({ basicInformation }) => basicInformation?.type === OrderType.DI),
          LABS_SELECT_PROVIDER:
            supportOrdersButton &&
            !!orders?.some(({ basicInformation }) => basicInformation?.type === OrderType.LAB),
        },
      };
    }, [orders, referral, supportOrdersButton]);

  useEffect(() => {
    launchButton.enableLaunchButtons(launchButtonsConfig);
  }, [launchButton, launchButtonsConfig]);

  const onLaunchButtonClicked = useCallback(
    (payload?: AppLaunchButtonClickPayload) => {
      const { realEstate } = payload ?? {};

      switch (realEstate) {
        case ReferralRealEstate.REFERRAL_SELECT_PROVIDER:
          onReferralSelectButtonClicked();
          break;

        case OrdersRealEstate.DI_SELECT_PROVIDER:
          onDiOrdersSelectButtonClicked(selectedOrders);
          break;

        case OrdersRealEstate.LABS_SELECT_PROVIDER:
          onLabOrdersSelectButtonClicked(selectedOrders);
          break;

        default:
          orderAssistLogger.warning('Unexpected launch button trigger', {
            payload,
          });
          break;
      }
    },
    [
      onDiOrdersSelectButtonClicked,
      onLabOrdersSelectButtonClicked,
      onReferralSelectButtonClicked,
      selectedOrders,
    ],
  );

  useEffect(() => {
    launchButton.subscribe('launchButtonClicked', onLaunchButtonClicked);

    return () => {
      launchButton.unsubscribe('launchButtonClicked', onLaunchButtonClicked);
    };
  }, [launchButton, onLaunchButtonClicked]);
};
