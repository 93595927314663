import { Team, useFeatureFlag } from '@getvim/feature-flags-react';
import { useFlowState } from '@getvim/flow-context-provider';
import React, { createContext, useContext } from 'react';
import { some } from 'lodash-es';
import { UserConfig } from '../../../../hooks';
import { useSourceConfig } from '../../../../hooks/useSourceConfig';

interface OrderAssistAppFeatureFlags {
  shouldUseAdapterConfigFF: boolean;
  useUserExternalIdForSearchIds: boolean;
  useDiQuickSpecialtyFF: boolean;
  useReferralQuickSpecialtyFF: boolean;
  shouldFloridaBlueSupportFacility: boolean;
  isReadonlyMode: boolean;
  showNotificationFF: boolean;
  showNotificationForZeroResults: boolean;
  addCurrentScreenToSearchPerformed: boolean;
  shouldTrackAppEnabledAnalytics: boolean;
  autoSearchWhenDIInjectedButtonClicked: boolean;
  shouldUseMaxCptsConfig: boolean;
  shouldUseSourceVaultTokens: boolean;
}

const OrderAssistAppFeatureFlagsContext = createContext<OrderAssistAppFeatureFlags>({
  shouldUseAdapterConfigFF: false,
  useUserExternalIdForSearchIds: false,
  useDiQuickSpecialtyFF: false,
  useReferralQuickSpecialtyFF: false,
  shouldFloridaBlueSupportFacility: false,
  isReadonlyMode: false,
  showNotificationFF: false,
  showNotificationForZeroResults: false,
  addCurrentScreenToSearchPerformed: false,
  shouldTrackAppEnabledAnalytics: false,
  autoSearchWhenDIInjectedButtonClicked: false,
  shouldUseMaxCptsConfig: false,
  shouldUseSourceVaultTokens: false,
});

export const OrderAssistFeatureFlgasWrapper: React.FC = ({ children }) => {
  const { userConfig } = useFlowState<{
    userConfig: UserConfig;
  }>();

  const {
    organization: { id: organizationId, name: organizationName, ehrSystem },
    adapterName,
    workspaceConfig: { agentVersion, agentType },
  } = userConfig;

  const [shouldUseAdapterConfigFF, shouldUseAdapterConfigFFLoading] = useFeatureFlag({
    flagName: 'useAdapterConfigProviderSelection',
    defaultValue: false,
  });

  const [useUserExternalIdForSearchIds, useUserExternalIdForSearchIdsLoading] = useFeatureFlag({
    flagName: 'useUserExternalIdForSearchIds',
    defaultValue: false,
  });

  const [useDiQuickSpecialtyFF, useDiQuickSpecialtyFFLoading] = useFeatureFlag({
    flagName: 'useQuickSpecialty',
    defaultValue: false,
    flagContext: {
      organizationId,
      adapterName,
    },
  });
  const [useReferralQuickSpecialtyFF, useReferralQuickSpecialtyFFLoading] = useFeatureFlag({
    flagName: 'useReferralQuickSpecialty',
    defaultValue: false,
    flagContext: {
      organizationId,
      // TODO: verify values in new config
      adapterName: ehrSystem,
    },
  });

  const [shouldFloridaBlueSupportFacility, shouldFloridaBlueSupportFacilityLoading] =
    useFeatureFlag({
      flagName: 'shouldFloridaBlueSupportFacility',
      team: Team.OrderOptimization,
      defaultValue: false,
      flagContext: { organizationId },
    });

  const { source } = useSourceConfig(shouldFloridaBlueSupportFacility);

  const [isReadonlyMode, isReadonlyModeLoading] = useFeatureFlag({
    flagName: 'shouldActivateReadonlyMode',
    defaultValue: false,
    team: Team.OrderOptimization,
    flagContext: {
      source,
      organizationId,
    },
  });

  const [showNotificationFF, showNotificationFFLoading] = useFeatureFlag({
    flagName: 'orderAssistWidgetShowNotification',
    defaultValue: false,
    flagContext: {
      organizationId,
      agentVersion,
      agentType,
    },
  });

  const [showNotificationForZeroResults, showNotificationForZeroResultsLoading] = useFeatureFlag({
    flagName: 'showNotificationForZeroResults',
    defaultValue: false,
    flagContext: {
      organizationId,
    },
  });

  const [addCurrentScreenToSearchPerformed, addCurrentScreenToSearchPerformedLoading] =
    useFeatureFlag({
      flagName: 'addCurrentScreenToSearchPerformed',
      defaultValue: false,
      flagContext: { organizationId },
    });

  const [shouldTrackAppEnabledAnalytics, shouldTrackAppEnabledAnalyticsLoading] = useFeatureFlag({
    flagName: 'shouldTrackAppEnabledAnalytics',
    defaultValue: false,
    flagContext: {
      organizationId,
    },
  });

  const [autoSearchWhenDIInjectedButtonClicked, autoSearchWhenDIInjectedButtonClickedLoading] =
    useFeatureFlag({
      flagName: 'autoSearchWhenDIInjectedButtonClicked',
      defaultValue: false,
      flagContext: {
        organizationId,
        adapterName,
      },
    });

  const [shouldUseMaxCptsConfig, shouldUseMaxCptsConfigLoading] = useFeatureFlag({
    flagName: 'shouldUseMaxCptsConfig',
    defaultValue: false,
    flagContext: {
      organizationId,
    },
  });

  const [shouldUseSourceVaultTokens, shouldUseSourceVaultTokensLoading] = useFeatureFlag({
    flagName: 'shouldUseSourceVaultTokens',
    defaultValue: false,
    team: Team.PatientInsights,
    namespace: 'order_assist',
    flagContext: {
      organizationId_string: organizationId?.toString(),
      organizationName,
    },
  });

  if (
    some([
      shouldUseAdapterConfigFFLoading,
      useUserExternalIdForSearchIdsLoading,
      useDiQuickSpecialtyFFLoading,
      useReferralQuickSpecialtyFFLoading,
      shouldFloridaBlueSupportFacilityLoading,
      isReadonlyModeLoading,
      showNotificationFFLoading,
      showNotificationForZeroResultsLoading,
      addCurrentScreenToSearchPerformedLoading,
      shouldTrackAppEnabledAnalyticsLoading,
      autoSearchWhenDIInjectedButtonClickedLoading,
      shouldUseMaxCptsConfigLoading,
      shouldUseSourceVaultTokensLoading,
    ])
  ) {
    return null;
  }

  return (
    <OrderAssistAppFeatureFlagsContext.Provider
      value={{
        shouldUseAdapterConfigFF,
        useUserExternalIdForSearchIds,
        useDiQuickSpecialtyFF,
        useReferralQuickSpecialtyFF,
        shouldFloridaBlueSupportFacility,
        isReadonlyMode,
        showNotificationFF,
        showNotificationForZeroResults,
        addCurrentScreenToSearchPerformed,
        shouldTrackAppEnabledAnalytics,
        autoSearchWhenDIInjectedButtonClicked,
        shouldUseMaxCptsConfig,
        shouldUseSourceVaultTokens,
      }}
    >
      {children}
    </OrderAssistAppFeatureFlagsContext.Provider>
  );
};

export const useOrderAssistAppFeatureFlags = (): OrderAssistAppFeatureFlags =>
  useContext(OrderAssistAppFeatureFlagsContext);
