import { ProductSource } from '@getvim-core-apps/organizations';
import {
  SourceConfig,
  SourceConfigurations,
  SourceConfigurationTypes,
  WidgetSource,
} from '../components/app/types/sourceConfigurations';

export const DEFAULT_MAPPING: SourceConfigurations = {
  supportIcdCpt: true,
  supportLanguage: true,
  supportOrdersButton: true,
  supportPlaceOfService: true,
  supportFacilityName: true,
  supportCost: false,
  supportWiderDistanceFilter: true,
  defaultDistanceFilter: 50,
  useSourceFreeText: false,
  supportInsurancePlanInput: false,
  collapsePreferredProviders: false,
  supportReferralRequest: false,
  supportsAcceptingNewPatientsLongTooltip: false,
  maxCptsPerSearch: 3,
};

const configMapping: Map<WidgetSource, SourceConfig> = new Map([
  [
    ProductSource.BlueCrossBlueShield,
    {
      [SourceConfigurationTypes.AUTHENTICATED]: {
        ...DEFAULT_MAPPING,
        // When removing shouldFloridaBlueSupportFacility change here accordingly
        supportIcdCpt: false,
        supportOrdersButton: false,
        supportPlaceOfService: false,
        supportFacilityName: false,
        useSourceFreeText: true,
        supportReferralRequest: true,
      },
    },
  ],
  [
    ProductSource.UnitedHealthCare,
    {
      [SourceConfigurationTypes.AUTHENTICATED]: {
        ...DEFAULT_MAPPING,
        supportCost: true,
        supportWiderDistanceFilter: true,
        defaultDistanceFilter: 20,
        supportsAcceptingNewPatientsLongTooltip: true,
        maxCptsPerSearch: 1,
      },
    },
  ],
  [
    ProductSource.Cigna,
    {
      [SourceConfigurationTypes.AUTHENTICATED]: {
        ...DEFAULT_MAPPING,
        supportIcdCpt: false,
        supportOrdersButton: false,
        supportPlaceOfService: false,
        supportFacilityName: false,
      },
    },
  ],
  [
    ProductSource.MockDemoInsurer,
    {
      [SourceConfigurationTypes.AUTHENTICATED]: {
        ...DEFAULT_MAPPING,
        supportIcdCpt: false,
        supportOrdersButton: false,
        supportPlaceOfService: false,
        supportFacilityName: false,
        supportReferralRequest: false,
      },
    },
  ],
]);

export default configMapping;
