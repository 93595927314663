export enum AppEnv {
  LOCAL = 'local',
  DEV = 'dev',
  STAGING = 'staging',
  DEMO = 'demo',
  PROD = 'prod',
}

const devEnvironments = ['local', 'dev', 'staging', 'demo'];
export const isProduction = !devEnvironments.includes(window.$vim_environment.APP_ENV);
