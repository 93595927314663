import { Insurer } from '@getvim-os/standard';
import { PatientInsurance } from '@getvim-os/types';
import { PatientSourceVaultToken } from '@getvim/patient-lookup-service/types';

const findInsurerEnum = (source: string | undefined) =>
  Object.values(Insurer).find((value) => value === source);

const getPatientSourceVaultTokensInsurer = (
  patientSourceVaultTokens?: PatientSourceVaultToken[],
) => {
  const insurerSource = patientSourceVaultTokens?.find(
    (currentSource) => currentSource.isInsurer,
  )?.source;

  return findInsurerEnum(insurerSource);
};

export const getPatientInsurance = (
  patientInsurance: PatientInsurance | undefined,
  patientSourceVaultTokens?: PatientSourceVaultToken[],
) => {
  const patientSourceVaultTokensInsurer =
    getPatientSourceVaultTokensInsurer(patientSourceVaultTokens);

  if (patientInsurance && !patientInsurance.insurer) {
    patientInsurance = { ...patientInsurance, insurer: patientSourceVaultTokensInsurer };
  }

  return patientInsurance;
};
