import { PatientContentSupplierIdentifiers } from '@getvim-core-apps/organizations';
import { orderAssistLogger } from '../components/app/logger';
import { PatientSourceVaultToken } from '@getvim/patient-lookup-service/types';

class VimPatientIdsManager {
  private vimPatientId: string | undefined;
  private patientContentSupplierIdentifiers: PatientContentSupplierIdentifiers | undefined;
  private patientSourceVaultTokens: PatientSourceVaultToken[] | undefined;

  public getVimPatientId(): string | undefined {
    return this.vimPatientId;
  }

  public getPatientContentSupplierIdentifiers(): PatientContentSupplierIdentifiers | undefined {
    return this.patientContentSupplierIdentifiers;
  }

  public getPatientSourceVaultTokens(): PatientSourceVaultToken[] | undefined {
    return this.patientSourceVaultTokens;
  }

  public handleUpdateIds(
    vimPatientId?: string,
    contentSupplierIdentifiers?: PatientContentSupplierIdentifiers,
    patientSourceVaultTokens?: PatientSourceVaultToken[],
  ): void {
    if (!vimPatientId || (!contentSupplierIdentifiers && !patientSourceVaultTokens)) {
      orderAssistLogger.warning(
        'vimPatientId or (contentSupplierIdentifiers and patientSourceVaultTokens) undefined',
        {
          vimPatientId,
          contentSupplierIdentifiers,
          patientSourceVaultTokens,
        },
      );
    }
    this.vimPatientId = vimPatientId;
    this.patientContentSupplierIdentifiers = contentSupplierIdentifiers;
    this.patientSourceVaultTokens = patientSourceVaultTokens;
  }

  public resetIds() {
    this.vimPatientId = undefined;
    this.patientContentSupplierIdentifiers = undefined;
    this.patientSourceVaultTokens = undefined;
  }
}

export const vimPatientIdsManager = new VimPatientIdsManager();
