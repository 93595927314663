import { useFlowState } from '@getvim/flow-context-provider';
import React, { FC, useEffect, useState } from 'react';
import { UserConfig } from '../../../hooks';
import { isPatientEligible } from '../../../utils/patientEligibility';
import { Patient } from '../../app/types';
import { Button } from '../components';
import { useOrderAssistAppFeatureFlags } from '../../app/components/order-assist-app/OrderAssistFFWrapper';

export const ReferralSelectProviderInlineWidget: FC = () => {
  const [shouldDisplay, setShouldDisplay] = useState(false);
  const { userConfig, patient } = useFlowState<{ userConfig: UserConfig; patient: Patient }>();

  const { shouldUseSourceVaultTokens } = useOrderAssistAppFeatureFlags();

  useEffect(() => {
    const handleShouldDisplay = async (patientData: Patient) => {
      const isEligible = await isPatientEligible(
        patientData,
        userConfig,
        shouldUseSourceVaultTokens,
      );
      setShouldDisplay(isEligible);
    };

    handleShouldDisplay(patient);
    // TODO do it like orders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient?.patientId, userConfig]);

  if (!shouldDisplay) {
    return null;
  }

  return <Button />;
};
